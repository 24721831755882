import './main.css'
import Alpine from 'alpinejs'
import axios from 'axios'
import { reduce } from 'lodash'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

window.Alpine = Alpine
window.axios = axios

const notyf = new Notyf({
    duration: 3000,
    position: { x: 'center', y: 'bottom' }
})

const fireSuccess = (message) => {
    const success = notyf.success({
        message: message,
        className: 'toaster toaster--success',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">\n' +
            '  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />\n' +
            '</svg>\n'
    })
    success.on('click', () => notyf.dismiss(success))
}

const copyLink = async (value) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        try {
            await navigator.clipboard.writeText(value)
            console.log('Link copied successfully:', value)
        } catch (error) {
            console.error('Failed to copy the link:', error)
        }
    } else {
        console.warn('Clipboard API is not supported. Falling back to manual copy.')
        // Fallback to manual copy
        const tempInput = document.createElement('input')
        tempInput.value = value
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand('copy')
        document.body.removeChild(tempInput)
        console.log('Link copied manually:', value)
    }
}

// Create a custom axios instance with default config
const axiosInstance = axios.create({
    headers: {
        'X-WP-Nonce': wpflp.rest_nonce
    }
})

const submit = (url, data) => {
    return axiosInstance.post(url, data)
}

// frontend.
window.Alpine.store('cart', {
    loading: false,
    count: 0,
    items: [],
    total: 0,
    totalBase: 0,
    totalPoints: 0,
    isEdit: false,
    initCart(items, isEdit) {
        this.isEdit = isEdit
        this.items = items
        this.calcTotal()
    },
    initializeCart(count) {
        this.count = count
    },
    calcTotal() {
        this.total = reduce(this.items, (total, product) => total + (product.price * product?.qty || 1), 0) || 0
        this.totalBase = reduce(this.items, (total, product) => total + (product.base_price * product?.qty || 1), 0) || 0
        this.totalPoints = reduce(this.items, (total, product) => total + parseFloat(product.points * product?.qty || 1), 0) || 0
    },
    addToCart(productId, qty) {
        this.loading = true
        submit(wpflp.routes['cart/store'], { id: productId, qty })
            .then((response) => {
                if (response.data.success) {
                    this.count = response.data.count

                    if (response.data?.success) {
                        fireSuccess(response.data.message)
                    }
                }
            })
            .catch(error => {
                console.error('Error adding to cart:', error)
            })
            .finally(() => this.loading = false)
    },
    updateCart(productId, qty) {
        this.loading = true
        submit(wpflp.routes['cart/update'], { id: productId, qty })
            .then((response) => {
                if (response.data?.success) {
                    this.count = response.data.count
                    this.items = response.data.items
                    this.calcTotal()

                    fireSuccess(response.data.message)
                }

            })
            .catch(error => console.error('Error updating cart:', error))
            .finally(() => this.loading = false)
    },
    remove(productId) {
        this.loading = true
        submit(wpflp.routes['cart/remove'], { id: productId })
            .then((response) => {
                if (response.data?.success) {
                    this.count = response.data.count
                    this.items = response.data.items
                    this.calcTotal()

                    fireSuccess(response.data.message)
                }
            })
            .catch(error => console.error('Error removing product from cart:', error))
            .finally(() => this.loading = false)
    },
    generateLink() {
        this.loading = true
        submit(wpflp.routes['cart/share'])
            .then((response) => {
                console.log(response.data.response)
                if (response.data?.response?.link) {
                    copyLink(response.data.response.link)
                    fireSuccess(response.data.message)
                } else {
                    this.loading = false
                }
            }).catch(error => {
            this.loading = false
            console.error('Error generating share cart link:', error)
        })
    },
    synchronize() {
        this.loading = true
        submit(wpflp.routes['cart/sync'])
            .then((response) => {
                if (response.data?.response?.hash) {
                    fireSuccess(response.data.message)

                    window.location.href = 'https://flpshop.rs/sync/korpa/' + response.data.response.hash
                } else {
                    this.loading = false
                }
            })
            .catch(error => {
                this.loading = false
                console.error('Error removing product from cart:', error)
            })
    },
    buyNow(productId) {
        this.loading = true
        submit(wpflp.routes['cart/buy-now'], {id: productId})
            .then((response) => {
                if (response.data?.response?.hash) {
                    fireSuccess(response.data.message)

                    window.location.href = 'https://flpshop.rs/sync/korpa/' + response.data.response.hash
                } else {
                    this.loading = false
                }
            })
            .catch(error => {
                this.loading = false
                console.error('Error removing product from cart:', error)
            })
    }
})

Alpine.store('dialog', {
    open: null,
    data: {},
    confirm(type, data = {}) {
        this.open = type
        this.data = data
    }
})

Alpine.start()
